const stakingAddress = (() => {
  const validatorName = process.env.REACT_APP_VALIDATOR;

  if (validatorName === "dacm") {
    return "terra1723d2ysqvtxjqngjamtcyfyyulas6tf6u5fx3l";
  } else if (validatorName === "hypersphere") {
    return "terra1k4gp6w3m0zl7ltvljx3hndaae9x85g8lauku0r";
	} else if (validatorName === "pilot") {
		return "terra1jru7kq9wktt7yn4s3wr7lf8vwspqugl09wue3f";
	}

  throw new Error(`Unknown validator name: ${validatorName}`);
})();

export const mainnetConfig = {
  network: {
    name: "mainnet",
    chainID: "columbus-5",
    lcd: "https://lcd.terra.dev",
    walletconnectID: 0,
    fcd: "https://fcd.terra.dev",
    localterra: false,
  },
  contractAddresses: {
    Staking: stakingAddress,
  },
  baseApiURL: "https://apis.staderlabs.com",
  terraStationExtensionURL:
    "https://chrome.google.com/webstore/detail/terra-station/aiifbnbfobpmeekipheeijimdpnlpgpp",
};
